// @flow

import { getCookie, setCookie, removeCookie, BehaviorSubject } from '../../lib/helpers'

/**
 * Indicated the type of consent the user has given.
 * @type {{PERSONAL: string, ESSENTIAL: string, UNDEFINED: string}}
 */
export const CONSENTS = Object.freeze({
  ESSENTIAL: 'functional',
  PERSONAL: 'full',
  TEMPORARY: 'temporary',
  UNDEFINED: ''
})
/**
 * Type annotation for flow
 * @type {string}
 */
type CONSENT_TYPE = $Values<typeof CONSENTS>

/**
 * The key used to store the user's preference in the client side cookies.
 * @type {string}
 */
const COOKIE_KEY = 'cookieConsent'
const VISITED_COOKIE_KEY = 'visitedUrls'

/**
 * The lifetime in days for which the user's preference is stored in the client side cookie.
 * @type {number}
 */
const COOKIE_DURATION = 90
const UpdateConsent = new BehaviorSubject()

const CookieConsent = {

  /**
   * Get the current user's consent which can be personal, essential or unset.
   * @returns {string}
   */
  getConsent: (): CONSENT_TYPE => {
    const cookie = getCookie(COOKIE_KEY)
    let consent = cookie
    UpdateConsent.next(consent)
    if (typeof cookie === 'undefined') {
      consent = CONSENTS.UNDEFINED
    } else if (cookie === CONSENTS.UNDEFINED || !Object.values(CONSENTS).includes(cookie)) {
      console.warn('Invalid cookie consent cookie')
      consent = CONSENTS.UNDEFINED
    }

    /** @TODO: Remove the migrateConsent call and the method after 01-03-2020 **/
    if (consent === CONSENTS.UNDEFINED && CookieConsent.migrateConsent()) {
      consent = CONSENTS.PERSONAL
    }
    removeCookie('privacybanner')
    /** end TODO **/

    return consent
  },
  /**
   * Set the users consent to personal or essential
   * @param consent
   * @param duration
   */
  setConsent: (consent: CONSENT_TYPE): void => {
    if (consent === CONSENTS.UNDEFINED || !Object.values(CONSENTS).includes(consent)) {
      throw new Error('Invalid consent type')
    }
    if (consent === CONSENTS.TEMPORARY) {
      setCookie(COOKIE_KEY, consent)
    } else {
      setCookie(COOKIE_KEY, consent, COOKIE_DURATION)
    }
    UpdateConsent.next(consent)
  },
  setEssentialConsent: (): void => {
    CookieConsent.setConsent(CONSENTS.ESSENTIAL)
  },
  setPersonalConsent: (): void => {
    CookieConsent.setConsent(CONSENTS.PERSONAL)
  },

  setTemporaryConsent: (): void => {
    CookieConsent.setConsent(CONSENTS.TEMPORARY)
  },

  getVisitedUrls: (): string => {
    return getCookie(VISITED_COOKIE_KEY)
  },

  addVisitedUrl: (): void => {
    const visitedUrls = JSON.parse(CookieConsent.getVisitedUrls() || '[]')
    const encodedUrl = encodeURIComponent(`${window.location.origin}${window.location.pathname}`)
    const isVisited = visitedUrls.includes(encodedUrl)
    if (isVisited) {
      return
    }

    visitedUrls.push(encodedUrl)

    if (visitedUrls.length < 3) {
      setCookie(VISITED_COOKIE_KEY, JSON.stringify(visitedUrls))
    } else {
      removeCookie(VISITED_COOKIE_KEY)
      CookieConsent.setTemporaryConsent()
    }
  },
  /**
   * Migrate the old privacybanner cookie to the cookieConsent cookie
   * @return  boolean
   * @TODO: Remove this method and its call in getConsent after 01-03-2020
   */
  migrateConsent: (): boolean => {
    const previousCookieKey = 'privacybanner'
    const previousCookieConsent = getCookie(previousCookieKey)

    if (previousCookieConsent === 'true') {
      CookieConsent.setPersonalConsent()
      removeCookie(previousCookieKey)
      return true
    }
    return false
  },
  CONSENTS: CONSENTS
}

export { CookieConsent, UpdateConsent }
