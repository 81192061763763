import * as cookie from 'js-cookie'

/**
 * Create a BehaviorSubject and subscribe to get the next value
 * @param {any} defaultValue
 * @private
 */
export const BehaviorSubject = (defaultValue) => {
  const subscribers = []
  let currentValue = null
  const next = (nextValue) => {
    if (currentValue !== nextValue) {
      currentValue = nextValue
      subscribers.forEach((subscriber) => subscriber(nextValue))
    }
  }
  return {
    subscribe: (subscriber) => {
      subscribers.push(subscriber)
      next(defaultValue)
    },
    next
  }
}

/**
 *
 * reduce the amount of times a function is fired, waiting for x milliseconds
 * @param fn the function fired
 * @param wait the amount of milliseconds to wait
 */
export const debounce = (fn, wait) => {
  let t
  return function () {
    clearTimeout(t)
    t = setTimeout(() => fn.apply(this, arguments), wait)
  }
}

/**
 * $(document).ready equivalent for the project
 * @param fn
 */
export const documentReady = (fn) => {
  if (document.readyState !== 'loading') {
    fn()
  } else {
    document.addEventListener('DOMContentLoaded', fn)
  }
}

/**
 * Generate an id, optional prefix can be added
 * @param prefix
 * @returns {string}
 */
export const generateUid = () => Math.random().toString(36).substr(2, 9)

/**
 * Gets cookie value
 * @param key
 * @returns {string|undefined}
 * @private
 */
export const getCookie = (key) => cookie.get(key)

export const removeCookie = (key) => cookie.remove(key)

/**
 * Stores the value for 'expires' duration
 * @param {string} key
 * @param {string} value
 * @param {Number} expires - Duration in days, if none is set then it will be a session cookie
 * @private
 */
export const setCookie = (key, value, expires) => {
  cookie.set(key, value, {
    expires,
    secure: true // Remove secure flag to test on non-SSL env
  })
}
