// @flow
import './cookie-wall-style.scss'
import type { CookieWallInterface } from './cookie-wall.interface'
import type { CookieWallPropTypes as PropTypes } from './cookie-wall.prop-types'
import { CookieConsent, CONSENTS, UpdateConsent } from '../../../models/cookie-consent/cookie-consent'
import { Element, instanceOf, string } from 'prop-types'

const JS_SELECTOR_BUTTON_OK = 'js-btn-ok'

export class CookieWall implements CookieWallInterface {
  static propTypes: PropTypes = {
    buttonText: string.isRequired,
    classList: [],
    $container: instanceOf(Element).isRequired,
    contentText: string.isRequired,
    updateCookieFn: Function,
    url: string.isRequired,
    urlText: string.isRequired
  }

  props: PropTypes

  constructor (props: PropTypes) {
    this.props = { ...props }
    if (typeof this.props.updateCookieFn === 'function') {
      UpdateConsent.subscribe(this.props.updateCookieFn)
    }
    let consent = CookieConsent.getConsent()
    if (consent === CONSENTS.UNDEFINED) {
      CookieConsent.addVisitedUrl()
      consent = CookieConsent.getConsent()
    }
    // Update the cookie whenever it changes
    // Initial State, checking if cookie is there
    this.setState({ consent })
  }

  // Internal component state
  componentState = {
    expires: 90 // Default number of days before cookie expires
  }

  setState = (props: Object) => {
    this.componentState = {
      ...this.componentState,
      ...props
    }
    this.render()
  }

  getState = () => this.componentState

  componentDidMount () {
    if (!this.getState().consent) {
      const $okButton: ?HTMLElement = this.props.$container.querySelector(`.${JS_SELECTOR_BUTTON_OK}`)
      if ($okButton) {
        $okButton.addEventListener('click', this.clickOkEvent)
      }
    }
  }

  clickOkEvent = () => {
    CookieConsent.setPersonalConsent()
    this.closeCookieWall()
  }

  closeCookieWall = () => {
    this.setState({ isClosed: true })
  }

  closeCookieWallThisSession = () => {
    const consent = CookieConsent.getConsent()
    if (consent === CONSENTS.UNDEFINED) {
      CookieConsent.setTemporaryConsent()
    }
    this.closeCookieWall()
  }

  render = () => {
    const { buttonText, classList, $container, urlText, contentText, url } = this.props
    // Render only if your not at the cookie consent page and the cookie is not accepted jet.
    $container.innerHTML = ''
    if (
      (
        !this.getState().isClosed &&
        !this.getState().consent
      ) &&
      $container
    ) {
      $container.innerHTML = `
      <section class="cc-banner__container ${classList ? classList.join(' ') : ''}">
        <article class="cc-banner__wrapper">
          <p class="cc-banner__text">
            ${contentText}
            <a href=${url} class="cc-banner__link">${urlText}</a>
          </p>
          <button
            aria-label="${contentText}"
            class="
            cc-banner__btn-ok
            ${JS_SELECTOR_BUTTON_OK}"
          >
            ${buttonText}
          </button>
        </article>
      </section>`
      this.componentDidMount()
    }
  }
}
