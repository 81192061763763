import { CookieWall, CookieConsent } from './index'

const selectorBanner = '.cc-banner'
const $container = document.querySelector(selectorBanner)
let cookieWall

if ($container) {
  const { buttonText, contentText, url, urlText } = $container.dataset

  if (buttonText && contentText && url && urlText) {
    // eslint-disable-next-line no-new
    cookieWall = new CookieWall({
      buttonText,
      $container,
      contentText,
      url,
      urlText
    })
  } else {
    const missingProps = { buttonText, contentText, url, urlText }
    console.warn('[CookieWall] There are missing properties, please provide them using data attributes. Given:', missingProps)
  }
} else {
  console.warn(`[CookieWall] Missing container, please provide an element with the class: ${selectorBanner}`)
}

window.CookieConsent = CookieConsent
window.CookieWall = cookieWall
